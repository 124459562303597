<template>
  <v-app>
    <div class="ErrorNotFound">
      <div class="Container">
        <h3>Error 404</h3>
        <h2>No se encontró la página a la que deseas ir...</h2>
        <div class="flexDK">
          <p>Te llevaremos a la página de inicio en</p>
          <div
            class="progress"
            style="margin: 0; margin-left: 30px"
            :data-percentage="100"
          >
            <span class="progress-left">
              <span class="progress-bar"></span>
            </span>
            <span class="progress-right">
              <span class="progress-bar"></span>
            </span>
            <div class="progress-value">
              <span>
                {{ resultCounter }}
              </span>
            </div>
          </div>
        </div>
        <v-btn small color="info" @click="goToHome">Ir al inicio</v-btn>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "ErrorNotFound",
  data() {
    return {
      seconds: 5,
      resultCounter: "00",
      timerRestart: null,
    };
  },
  created() {
    this.changeSeconds();
  },
  destroyed() {
    clearInterval(this.timerRestart);
  },
  methods: {
    changeSeconds() {
      this.timerRestart = setInterval(() => {
        if (this.seconds !== 0) {
          this.seconds--;
          this.resultCounter = `${
            this.seconds < 10 ? "0" + this.seconds : this.seconds
          }`;
        } else {
          clearInterval(this.timerRestart);
          this.goToHome();
        }
      }, 1000);
    },

    cleanSeconds() {
      this.seconds = 5;
    },

    goToHome() {
      this.$router.push({
        path: localStorage.getItem("userInfo") ? "/home" : "/sessions/login",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ErrorNotFound {
  height: 100vh;
  background-color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  h3 {
    margin-bottom: -5px;
    font-size: 19px;
  }
  h2 {
    margin-bottom: 0;
  }
  p {
    font-size: 16px;
    margin-top: 20px;
  }
  .flexDK {
    display: flex;
    align-items: center;
  }
}

$borderWidth: 5px;
$animationTime: 2.5s;
$border-color-default: rgb(223, 230, 237);
$border-color-progressbar: #50a5eb;
$size: 52px;
$howManySteps: 100; //this needs to be even.

.progress {
  width: $size !important;
  height: $size !important;
  line-height: $size;
  background: none;
  margin: auto;
  box-shadow: none;
  position: relative;
  top: 7px;
  left: 7px;
  /* background-color: #ffffff !important; */
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
  }
  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress-left {
    left: 0;
  }
  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $border-color-progressbar;
  }
  .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: ($size/2);
    border-bottom-right-radius: ($size/2);
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progress-right {
    right: 0;
    .progress-bar {
      left: -100%;
      border-top-left-radius: ($size/2);
      border-bottom-left-radius: ($size/2);
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }
  .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
    span {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

.colorHouse {
  fill: #00599d;
  color: #00599d;
}

.colorHouseDanger {
  fill: rgb(240, 64, 64);
  color: rgb(240, 64, 64);
}

/* This for loop creates the necessary css animation names
Due to the split circle of progress-left and progress right, we must use the animations on each side.
*/
@for $i from 1 through $howManySteps {
  $stepName: ($i * (100 / $howManySteps));

  //animation only the left side if below 50%
  @if $i <= ($howManySteps/2) {
    .progress[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        animation: loading-#{$i} $animationTime linear forwards;
      }
      .progress-left .progress-bar {
        animation: 0;
      }
    }
  }
  //animation only the right side if above 50%
  @if $i > ($howManySteps/2) {
    .progress[data-percentage="#{$stepName}"] {
      .progress-right .progress-bar {
        // eslint-disable-next-line prettier/prettier
        // eslint-disable-next-line prettier/prettier
        animation: loading-#{($howManySteps/2)}$animationTime
          linear
          forwards; //set the animation to longest animation
      }
      .progress-left .progress-bar {
        animation: loading-#{$i -
          ($howManySteps/2)}
          $animationTime
          linear
          forwards
          $animationTime;
      }
    }
  }
}

//animation
@for $i from 1 through ($howManySteps/2) {
  $degrees: (180/ ($howManySteps/2));
  $degrees: ($degrees * $i);
  @keyframes loading-#{$i} {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate($degrees);
      transform: rotate(#{$degrees}deg);
    }
  }
}
</style>
